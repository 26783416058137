import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { TranslateModule, TranslateLoader, TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { ToastrModule } from 'ngx-toastr';

import { CookieLawModule } from 'angular2-cookie-law';
import { DynamicConfigService } from './services/dynamic-config.service';
import { CookieModule } from 'ngx-cookie';
import { GridModule } from '@progress/kendo-angular-grid';
import { PagerModule } from '@progress/kendo-angular-pager';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { TimeDiffPipe } from './pipes/timedatediff.pipe';

import "@progress/kendo-angular-intl/locales/fr/all";
import { ToyotaHttpInterceptorService } from './services/toyota-http-interceptor.service';




registerLocaleData(localeIt);

const supportedLocales = [
  'en',
  'it',
  'de',
  'fr',
  'hu',
  'pl',
  'ro',
  'sr'
];

const browserLocale4 = navigator.language;
const browserLocale2 = browserLocale4.slice(0, 2);
var selectedLocale = supportedLocales[0];

for (var i = 0; i < supportedLocales.length; i++) {
  if (supportedLocales[i] == browserLocale2) {
    selectedLocale = browserLocale2;
    break;
  }
}

export function initEnv(dynamicConfig: DynamicConfigService) {
  return () => dynamicConfig.init();
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http,'assets/i18n/', '.json?v=3');
}

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent
    ],
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    NgbModule,
    RouterModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot({timeOut: 10000, positionClass: 'toast-bottom-right', preventDuplicates: true}),
    CookieLawModule,
    CookieModule.forRoot(),
    GridModule,
    PagerModule,
    TooltipModule,
    TranslateModule
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: initEnv, deps: [DynamicConfigService], multi: true },
    { provide: LOCALE_ID, useValue: selectedLocale },
    { provide: 'browserLocale2', useValue: browserLocale2 },
    { provide: HTTP_INTERCEPTORS, useClass: ToyotaHttpInterceptorService, multi: true },
    TimeDiffPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
