import { preserveWhitespacesDefault } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';

@Injectable({
  providedIn: 'root'
})
export class LanguageChangerService {

  constructor(
    private cookieService: CookieService
  ) { }

  private preSelectedLanguage = undefined;

  addLanguageInStorage(langCode){
    let currUserStorageData = undefined;
    if(!!navigator.platform.match(/iPhone|iPod|iPad/)) {
      currUserStorageData = JSON.parse(atob(this.cookieService.get('toyota')));
    } else {
      currUserStorageData = JSON.parse(localStorage.getItem('currentUser'));
    }

    if(typeof currUserStorageData!= 'undefined'){
      currUserStorageData.language = langCode;

      if(!!navigator.platform.match(/iPhone|iPod|iPad/)) {
        this.cookieService.put('toyota', btoa(JSON.stringify(currUserStorageData)), { expires: new Date((new Date().getTime() + 3600000*24*7)).toUTCString() });
      } else {
        localStorage.setItem('currentUser',JSON.stringify(currUserStorageData));
      }      
    }
  }

  public getpreSelectedLang(){
   return this.preSelectedLanguage
  }

  public setPreSelectedLang(langCode){
    this.preSelectedLanguage = langCode
  }
}
