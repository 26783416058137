const perms = [
    'show_page_assets',
    'show_page_activities',
    'show_page_collisions',
    'show_page_history',
    'show_page_efficiency',
    'show_page_products',
    'show_page_areas',
    'show_page_stock',
    'show_page_users',
    'show_page_companies',
    'edit_stock',
    'edit_user',
    'edit_company',
    'create_user',
    'create_company'
];

export const KIWITRON_USER_PERMS = perms.filter(perm => perm !== 'edit_stock');

export const ADMIN_USER_PERMS = perms.filter(perm => perm !== 'edit_stock');

export const FACTORY_USER_PERMS = perms.filter(
    perm => (
        perm === 'show_page_history' || 
        perm === 'show_page_efficiency' ||
        perm === 'show_page_products' || 
        perm === 'show_page_areas' ||
        perm === 'show_page_stock' ||
        perm === 'edit_stock'
    )
);

export const BASIC_USER_PERMS = perms.filter(
    perm => (
        perm === 'show_page_history' || 
        perm === 'show_page_products' || 
        perm === 'show_page_stock'
    )
);