import { Component, OnInit } from '@angular/core';
import { CustomizationService } from 'src/app/services/customization.service';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {

  constructor(private customizationService: CustomizationService) { }

  ngOnInit() {}

}
