import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { MediaBreakpointService } from 'src/app/services/media-breakpoint.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { UserService } from 'src/app/services/user.service';

declare interface RouteInfo {
	path: string;
	title: string;
	icon?: string;
	class?: string;
	divider?: string;
	//hide?: boolean,
	external?: boolean,
	protected: boolean,
	hasTag: string
}

export const ROUTES: RouteInfo[] = [
	//{ path: '/dashboard', title: 'sections.dashboard', icon: 'ni-tv-2 text-primary', hide: false },
	{ path: '/assets', title: 'sections.assets', icon: 'icon-ty_forklift text-primary', hasTag: 'show_page_assets', protected: true },
	{ path: '/activities', title: 'sections.activities', icon: 'icon-ty_anagrafica_articoli text-primary', hasTag: 'show_page_activities', protected: true },
	{ path: '/collisions', title: 'sections.collisions', icon: 'icon-ty_anagrafica_articoli text-primary', hasTag: 'show_page_collisions', protected: true },
	//{ path: '/pallet', title: 'sections.pallets', icon: 'icon-ty_carico-scarico text-primary'},
	{ path: '/history', title: 'sections.history', icon: 'icon-ty_storico text-primary', hasTag: 'show_page_history', protected: true },
	{ path: '/efficiency', title: 'sections.efficiency', icon: 'icon-ty_efficienza text-primary', hasTag: 'show_page_efficiency', protected: true },
	{ path: '/products', title: 'sections.products', icon: 'icon-ty_pallet text-primary', hasTag: 'show_page_products', protected: true },
	{ path: '/areas', title: 'sections.areas', icon: 'icon-ty_anagrafica_aree text-primary', hasTag: 'show_page_areas', protected: true },
	{ path: '/stock', title: 'sections.stock', icon: 'icon-ty_giacenza text_primary', hasTag: 'show_page_stock', protected: true },
	{ path: '/users', title: 'sections.users', icon: 'icon-ty_anagrafica_user text-primary', hasTag: 'show_page_users', protected: true },
	{ path: '/companies', title: 'sections.companies', icon: 'icon-ty_company text-primary', hasTag: 'show_page_companies', protected: true }
];

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

	userId;
	public menuItems: any[];
	public isCollapsed = true;

	constructor(
		private router: Router,
		private loginApi: LoginService,
		public screen: MediaBreakpointService,
		public navigationService: NavigationService,
		private userService: UserService
	) { }

	ngOnInit() {
		this.menuItems = ROUTES.filter(menuItem => menuItem);

		this.router.events.subscribe((event) => {
			this.isCollapsed = true;
		});
	}

	checkVisibility(menuItem: RouteInfo) {
		if(menuItem.protected === false) {
			return true;
		} else {
			if(typeof menuItem.protected !== 'undefined' && menuItem.protected === true) {
				const checkPerm = this.userService.getUserData().perms.find(perm => perm === menuItem.hasTag);

				if(checkPerm) {
					return true;
				} else {
					return false;
				}
			} else {
				return true;
			}
		}
	}

	public openApiDocs() {
		this.navigationService.reset();
		this.router.navigateByUrl("docs/api");
	}

	public logout() {
		this.loginApi.logout();
		setTimeout(() => {
			this.router.navigateByUrl('/login');
		}, 500);
	}

	getLogoRedirect() {
		if(this.userService.getUserData().utype == 1 || this.userService.getUserData().utype == 2) {
			return ['/assets'];
		} else {
			return ['/stock'];
		}
	}
}
