import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { PagedRequest } from '../models/paged-request';
import { DeleteUser_DTO, InsertUser_DTO, UpdateUser_DTO } from '../models/user';
import { ApiService } from './api.service';
import jwt_decode from 'jwt-decode';
import { ADMIN_USER_PERMS, BASIC_USER_PERMS, FACTORY_USER_PERMS, KIWITRON_USER_PERMS } from '../models/perms';


@Injectable({
	providedIn: 'root'
})
export class UserService {

	userData: any;

	constructor(
		private cookieService: CookieService,
		private api: ApiService
	) { }

	getUserData() {
		this.setUserData();
		return this.userData
	}

	setUserData() {		
		if (
			(typeof this.userData === 'undefined' || this.userData === null) && 
			(this.cookieService.get('toyota') || localStorage.getItem('currentUser'))
		) {
			let token = null;
			if (!!navigator.platform.match(/iPhone|iPod|iPad/) && this.cookieService.get('toyota')) {
				token = JSON.parse(atob(this.cookieService.get('toyota'))).token;
			} else {
				token = JSON.parse(localStorage.getItem('currentUser')).token;
			}

			let decodedToken = jwt_decode<any>(token);

			let perms = [];
			if(decodedToken.utype === 1) perms = KIWITRON_USER_PERMS;
			if(decodedToken.utype === 2) perms = ADMIN_USER_PERMS;
			if(decodedToken.utype === 4) perms = BASIC_USER_PERMS;
			if(decodedToken.utype === 6) perms = FACTORY_USER_PERMS;
			
			this.userData = {
				user_id: decodedToken.uid, 
				username: decodedToken.sub, 
				token: token,
				isAdmin: decodedToken.utype === 2,
				isKiwitron: decodedToken.utype === 1,
				utype: decodedToken.utype,
				perms: perms
			};
		}
	}

	getPagedResult(request: PagedRequest, isAutocomplete?: boolean) {
		let params = {};
		Object.assign(params, request);
		params['sort'] = JSON.stringify(params['sort'])

		return this.api.getUsers(params);
	}

	get(id: number) {
		return this.api.getUser(id);
	}

	insertUser(body: InsertUser_DTO) {
		return this.api.postUser(body);
	}

	updateUser(body: UpdateUser_DTO) {
		return this.api.putUser(body);
	}

	deleteUser(body: DeleteUser_DTO) {
		return this.api.deleteUser(body);
	}
}
