import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
//import { AlertServiceService } from './services/alert-service.service';
import { environment } from 'src/environments/environment'; 
import { NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from './services/login.service';
import { DynamicConfigService } from './services/dynamic-config.service';
import { DOCUMENT } from '@angular/common';
import { CustomizationService } from './services/customization.service';
import { LanguageChangerService } from './services/language-changer.service';
import { CookieService } from 'ngx-cookie';
//import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  @ViewChild('cookieLaw',{static:false})
  private cookieLawEl: any;

  @ViewChild('cookieLaw a',{static:false})
  private closeIcon: any;

  client: string;

  constructor(private translate: TranslateService, @Inject('browserLocale2') browserLocale2,
              //private alertService : AlertServiceService,
              private router: Router,
              private toastr: ToastrService,
              private login: LoginService,
              private dynamicConfig: DynamicConfigService,
              @Inject(DOCUMENT) private document: Document,
              private customizationService: CustomizationService,
              private langChanger:LanguageChangerService,
              private cookieService: CookieService,
              //private userService: UserService
            ) {

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');

    let langCode = browserLocale2
    //let currUserStorageData :any = JSON.parse(localStorage.getItem('currentUser'))
    let currUserStorageData = null;
      if(!!navigator.platform.match(/iPhone|iPod|iPad/) && this.cookieService.get('toyota')) {
        currUserStorageData = JSON.parse(atob(this.cookieService.get('toyota')));
      } else {
        currUserStorageData = JSON.parse(localStorage.getItem('currentUser'));
      }

    if(currUserStorageData && currUserStorageData.language){
      langCode = currUserStorageData.language
    }

    //this.userService.setGTMUserData();

     // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use(langCode);
    this.langChanger.setPreSelectedLang(langCode) // to correctly update dropdown pre selected language
}

navigateToTos() {
  this.router.navigateByUrl('/tos');
  //this.alertService.closeAlert()
}

navigateToLogin() {
  this.router.navigateByUrl('/login');
}

public dismiss(): void {
  this.cookieLawEl.dismiss();
  if(this.router.url=="/tos") this.navigateToLogin()
}

public rifiuta(): void {
  this.translate.get('cookie_alert').subscribe(translations => {

    /*this.alertService.sweetConfirm(translations['warning'], translations['message'], function (confirmed) {
      if (confirmed) {

      }
      else {
        // NO
      }

    });*/
  })
}

  title = 'Toyota';

  ngOnInit(){ }

  ngAfterViewInit() {
    this.dynamicConfig.loadStyle(this.customizationService.getStyle());
  }

  logoutOnClose(){
    this.login.logout()
  }

}
