import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserService } from './user.service';

@Injectable({
	providedIn: 'root'
})
export class ToyotaHttpInterceptorService implements HttpInterceptor {
	token: string = null;

	constructor(private router: Router, private userService: UserService, private toastr: ToastrService, private translateService: TranslateService) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		req = req.clone({
			withCredentials: true
		});

		return this.doRequest(req, next);
	}

	doRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if(req.url.endsWith('/login')) {
			return next.handle(req).pipe(
				catchError((e: HttpErrorResponse) => {
					this.router.navigate(['/login']);
					this.toastr.error('Username or password not found', '', { positionClass: 'toast-bottom-center', timeOut: 4000, progressBar: true });
					return throwError(e);
				})
			);
		} else {
			let userData = this.userService.getUserData();

			if((!this.token && userData) || (userData && (userData.token !== this.token))) {
				this.token = userData.token;
			}

			req = req.clone({
				headers: new HttpHeaders({'Authorization': 'Bearer ' + this.token})
			});
			
			return next.handle(req).pipe(
				catchError((e: HttpErrorResponse) => {
					if(e.status === 403) {
						this.router.navigate(['/login']);
						return throwError(e);
					} else if(e.status !== 409) {
						this.toastr.error(this.translateService.instant('toast.general_error'), '', { positionClass: 'toast-bottom-right', timeOut: 4000, progressBar: true });
						return throwError(e);
					} else {
						return throwError(e);
					}
				})
			);
		}
	}
}
