import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Angular2CookieLawService } from 'angular2-cookie-law';
import { CookieService } from 'ngx-cookie';
import * as _ from 'lodash';
import { UserService } from './user.service';

@Injectable({
	providedIn: 'root'
})
export class LoginService {
	private currentUserSubject: BehaviorSubject<any>;
	public currentUser: Observable<any>;

	// userPerms: BehaviorSubject<any>;
	// userInfos: BehaviorSubject<any>;
	userId: any;

	constructor(
		private api: ApiService,
		private cookie: Angular2CookieLawService,
		private cookieService: CookieService,
		private userService: UserService
	) {
		if (!!navigator.platform.match(/iPhone|iPod|iPad/)) {
			if (typeof this.cookieService.get('toyota') !== 'undefined') {
				this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(atob(this.cookieService.get('toyota'))));
			} else {
				this.currentUserSubject = new BehaviorSubject<any>(null);
			}
		} else {
			this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
		}

		this.currentUser = this.currentUserSubject.asObservable();
	}

	public getCookieStatus(): any {
		return this.cookie.seen('cookieLawSeen');
	}

	public getCurrentUser(): any {
		return this.currentUser;
	}

	public getcurrentUserValue(): any {
		return this.currentUserSubject.value;
	}

	public login(user, client = null) {
		if (typeof localStorage.getItem('currentUser') != 'undefined') {
			localStorage.removeItem('currentUser');
		}

		if (!!navigator.platform.match(/iPhone|iPod|iPad/)) {
			this.cookieService.remove('toyota');
		}

		return this.api.login(user).pipe(map(
			res => {
				if (res[0]) res = res[0];
				if (res.token) {
					const obj = {
						token: res.token,
					};
					
					if (!!navigator.platform.match(/iPhone|iPod|iPad/)) {
						this.cookieService.put('toyota', btoa(JSON.stringify(obj)), { expires: new Date((new Date().getTime() + 3600000 * 24 * 7)).toUTCString() });
					} else {
						localStorage.setItem('currentUser', JSON.stringify(obj));
					}

					this.currentUserSubject.next(user);
					this.currentUser = this.currentUserSubject.asObservable();
				}
				return res;
			}
		))
	}

	logout() {
		this.userService.userData = undefined;
		this.cookieService.remove("PHPSESSID");
		localStorage.removeItem('currentUser');
		this.cookieService.remove('toyota');
		this.currentUserSubject.next(null);
	}

	public isUserLogged(): boolean {
		const toyotaCookie = this.cookieService.get('toyota');
		if (!!navigator.platform.match(/iPhone|iPod|iPad/) && typeof toyotaCookie !== 'undefined' && toyotaCookie !== null && toyotaCookie.trim() !== '') { return true; }
		else { return !!localStorage.getItem('currentUser'); }
	}

	ngOnInit() { }

	ngOnDestroy() {
		this.logout();
	}
}
