import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router, UrlSerializer } from '@angular/router';

export interface HistoryListObject {
	url: string,
	params: any
}

@Injectable({
	providedIn: 'root'
})
export class NavigationService {
	private history: Array<HistoryListObject>;

	constructor(private router: Router, private location: Location, private serializer: UrlSerializer) {
		this.history = [];
	}

	push(historyListObject: HistoryListObject) {
		this.history.push(historyListObject);
	}

	pop(url: string) {
		let i = this.getLastIndexOfUrl(url);
		if(i >= 0) { this.history.splice(i) }
	}

	reset() {
		this.history = [];
	}

	getHistory() {
		return this.history;
	}

	getLastIndexOfUrl(url: string): number {
		let i = -1;
		this.history.forEach((element, index) => {
			if(element.url === url) {
				i = index;
			}
		});

		return i;
	}
}
