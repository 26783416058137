import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/";

import { environment } from 'src/environments/environment';
import { PagedResponse } from "../models/paged-response";
import { DeleteUser_DTO, InsertUser_DTO, UpdateUser_DTO, User } from "../models/user";
import { DeleteTenant_DTO, InsertTenant_DTO, Tenant, UpdateTenant_DTO } from "../models/tenant";
import { Site } from "../models/site";
import { InsertResponse } from "../models/insert-response";
import { UpdateResponse } from "../models/update-response";
import { DeleteResponse } from "../models/delete-response";
import { DeleteProduct_DTO, InsertProduct_DTO, Product, UpdateProduct_DTO, UploadProduct_DTO } from "../models/product";
import { Area, DeleteArea_DTO, InsertArea_DTO, UpdateArea_DTO } from "../models/area";
import { Asset, UpdateAsset_DTO } from "../models/asset";
import { DeletePallet_DTO, InsertPallet_DTO, Pallet, UpdatePallet_DTO, UpdateProductPallets_DTO } from "../models/pallet";
import { Stock } from "../models/stock";
import { Activity } from "../models/activity";
import { Collision } from "@progress/kendo-angular-popup";

@Injectable({
	providedIn: "root"
})
export class ApiService {
	baseUrl = environment.api_host;

	constructor(private http: HttpClient) { }

	// login
	login(user): Observable<any> {
		let req;
		if (user.password) {
			req = {
				username: user.username,
				password: user.password
			}
		} else {
			req = {
				username: user.username,
			}
		}

		if (user.new_password) {
			req = {
				username: user.username,
				password: user.old_password,
				new_password: user.new_password
			}
		}

		return this.http.post(`${this.baseUrl}/login`, req);
	}

	//SECTION users
	getUsers(params: any): Observable<PagedResponse<User>> {
		return this.http.get<PagedResponse<User>>(`${this.baseUrl}/users`, { params: params });
	}

	getUser(id: number): Observable<User> {
		return this.http.get<User>(`${this.baseUrl}/user/${id}`);
	}

	postUser(body: InsertUser_DTO): Observable<InsertResponse<User>> {
		return this.http.post<InsertResponse<User>>(`${this.baseUrl}/user`, body);
	}

	putUser(body: UpdateUser_DTO): Observable<UpdateResponse<User>> {
		return this.http.put<UpdateResponse<User>>(`${this.baseUrl}/user/${body.id}`, body);
	}

	deleteUser(body: DeleteUser_DTO): Observable<DeleteResponse> {
		return this.http.delete<DeleteResponse>(`${this.baseUrl}/user/${body.id}`);
	}
	//!SECTION

	//SECTION tenants
	getTenants(params: any): Observable<PagedResponse<Tenant>> {
		return this.http.get<PagedResponse<Tenant>>(`${this.baseUrl}/tenants`, { params: params });
	}
	getTenant(id: number): Observable<Tenant> {
		return this.http.get<Tenant>(`${this.baseUrl}/tenant/${id}`);
	}

	postTenant(body: InsertTenant_DTO): Observable<InsertResponse<Tenant>> {
		return this.http.post<InsertResponse<Tenant>>(`${this.baseUrl}/tenant`, body);
	}

	putTenant(body: UpdateTenant_DTO): Observable<UpdateResponse<Tenant>> {
		return this.http.put<UpdateResponse<Tenant>>(`${this.baseUrl}/tenant/${body.id}`, body);
	}

	deleteTenant(body: DeleteTenant_DTO): Observable<DeleteResponse> {
		return this.http.delete<DeleteResponse>(`${this.baseUrl}/tenant/${body.id}`);
	}
	//!SECTION

	//SECTION sites
	getSites(params: any): Observable<PagedResponse<Site>> {
		return this.http.get<PagedResponse<Site>>(`${this.baseUrl}/sites`, { params: params });
	}
	//!SECTION

	//SECTION products
	getProduct(id: number): Observable<Product> {
		return this.http.get<Product>(`${this.baseUrl}/product/${id}`);
	}

	getProducts(params: any): Observable<PagedResponse<Product>> {
		if (params.export != null){
			
			return this.http.get<PagedResponse<Product>>(`${this.baseUrl}/products`, { params: params,  responseType: 'text' as 'json' });
		}else{
			return this.http.get<PagedResponse<Product>>(`${this.baseUrl}/products`, { params: params });
		}
	}

	postProduct(body: InsertProduct_DTO): Observable<InsertResponse<Product>> {
		return this.http.post<InsertResponse<Product>>(`${this.baseUrl}/product`, body);
	}

	uploadProduct(body: UploadProduct_DTO): Observable<InsertResponse<Product>> {
		return this.http.post<InsertResponse<Product>>(`${this.baseUrl}/product/upload`, body);
	}

	putProduct(body: UpdateProduct_DTO): Observable<UpdateResponse<Product>> {
		return this.http.put<UpdateResponse<Product>>(`${this.baseUrl}/product/${body.id}`, body);
	}

	deleteProduct(body: DeleteProduct_DTO): Observable<DeleteResponse> {
		return this.http.delete<DeleteResponse>(`${this.baseUrl}/product/${body.id}`);
	}
	//!SECTION


	//SECTION areas
	getArea(id: number): Observable<Area> {
		return this.http.get<Area>(`${this.baseUrl}/area/${id}`);
	}

	getAreas(params: any): Observable<PagedResponse<Area>> {
		return this.http.get<PagedResponse<Area>>(`${this.baseUrl}/areas`, { params: params });
	}

	postArea(body: InsertArea_DTO): Observable<InsertResponse<Area>> {
		
		return this.http.post<InsertResponse<Area>>(`${this.baseUrl}/area`, body);
	}

	putArea(body: UpdateArea_DTO): Observable<UpdateResponse<Area>> {
		return this.http.put<UpdateResponse<Area>>(`${this.baseUrl}/area/${body.id}`, body);
	}

	deleteArea(body: DeleteArea_DTO): Observable<DeleteResponse> {
		return this.http.delete<DeleteResponse>(`${this.baseUrl}/area/${body.id}`);
	}
	//!SECTION

	//SECTION assets
	getAsset(id: number): Observable<Asset> {
		return this.http.get<Asset>(`${this.baseUrl}/asset/${id}`);
	}

	getAssets(params: any): Observable<PagedResponse<Asset>> {
		return this.http.get<PagedResponse<Asset>>(`${this.baseUrl}/assets`, { params: params });
		
	}

	putAsset(body: UpdateAsset_DTO): Observable<UpdateResponse<Asset>> {
		return this.http.put<UpdateResponse<Asset>>(`${this.baseUrl}/asset/${body.id}`, body);
	}

	activeAsset(assetId:number): Observable<UpdateResponse<Asset>> {
		return this.http.put<UpdateResponse<Asset>>(`${this.baseUrl}/asset/activation/${assetId}`, null);
	}

	updateAssetKConnect(assetId: number, serial: string) {
		return this.http.put<UpdateResponse<Asset>>(`${this.baseUrl}/asset/updateDevice/${assetId}`, {device_serial: serial});
	}

	//!SECTION

	//SECTION
	getActivities(params:any):  Observable<PagedResponse<Activity>> {
		return this.http.get<PagedResponse<Activity>>(`${this.baseUrl}/activities`, { params: params });
	}
	//!SECTION

	//SECTION Collisions
	getCollisions(params:any):  Observable<PagedResponse<Collision>> {
		return this.http.get<PagedResponse<Collision>>(`${this.baseUrl}/collisions`, { params: params });
	}
	//!SECTION

	//SECTION pallets
	getPallet(id: number): Observable<Pallet> {
		return this.http.get<Pallet>(`${this.baseUrl}/pallet/${id}`);
	}
	postPallet(body: InsertPallet_DTO): Observable<InsertResponse<Pallet>> {
		return this.http.post<InsertResponse<Pallet>>(`${this.baseUrl}/pallet`, body);
	}

	getPallets(params: any): Observable<PagedResponse<Pallet>> {
		return this.http.get<PagedResponse<Pallet>>(`${this.baseUrl}/pallets`, { params: params });
	}

	putPallet(body: UpdatePallet_DTO): Observable<UpdateResponse<Pallet>> {
		return this.http.put<UpdateResponse<Pallet>>(`${this.baseUrl}/pallet/${body.id}`, body);
	}

	deletePallet(body: DeletePallet_DTO): Observable<DeleteResponse> {
		return this.http.delete<DeleteResponse>(`${this.baseUrl}/pallet/${body.id}`);
	}

	updatePalletsOfProduct(body: UpdateProductPallets_DTO) {
		return this.http.put<UpdateResponse<any>>(`${this.baseUrl}/pallets/product/${body.product_id}`, { areas: body.areas });
	}

	//SECTION pallets

	//SECTION stock
	getStock(params: any): Observable<PagedResponse<Stock>> {
		return this.http.get<PagedResponse<Stock>>(`${this.baseUrl}/stock`, { params: params });
	}
	//SECTION stock

	//SECTION history
	getHistory(params: any): Observable<PagedResponse<History>> {
		return this.http.get<PagedResponse<History>>(`${this.baseUrl}/history`, { params: params });
	}
	//SECTION history
}