import { Injectable, isDevMode } from '@angular/core';
import { CookieService } from 'ngx-cookie';

@Injectable({
    providedIn: 'root'
})
export class CustomizationService {
    private url_part: string;
    private client: string;

    constructor(private cookieService: CookieService) {
        this.init();
    }

    public init() {
        const forceEnvironment = 'prod';
        this.url_part = window.location.hostname;

        //let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        let currentUser = null;
        if(this.cookieService.get('toyota')) {
            currentUser = JSON.parse(atob(this.cookieService.get('toyota')))
        } else {
            currentUser = JSON.parse(localStorage.getItem('currentUser'));
        }
        
        if(currentUser && currentUser.client) {
            this.client = currentUser.client;

            if(this.url_part.startsWith('app.') || this.url_part.startsWith('app-dev.')) {
            }

            return;
        }

        if (isDevMode() && forceEnvironment !== null) {
            this.client = forceEnvironment;

            return;
        }

        if (this.url_part.includes('test')) {
            this.client = 'test';
        } else if (this.url_part.includes('dev')) {
            this.client = 'dev';
        } else {
            this.client = 'prod';
        }
    }

    getStyle(): string {
        if (this.client === 'dev') {
            return 'style-dev.css';
        } else if (this.client === 'test') {
            return 'style-test.css';
        }else {
            return 'style-prod.css';
        }
    }

    getTheme(): string {
        if (this.client === 'dev') {
            return 'theme-dev.css';
        } else if (this.client === 'test') {
            return 'theme-test.css';
        } else {
            return 'theme-prod.css';
        }
    }

    getConfig(): string {
        if (this.client === 'dev') {
            return 'assets/config/dev.config.json';
        } else if (this.client === 'test') {
            return 'assets/config/test.config.json';
        } else {
            return 'assets/config/prod.config.json';
        }
    }

    getClient() {
        return this.client;
    }

    setClient(client:string) {
        this.client = client;
    }
}
