import { from } from 'rxjs';
import { DynamicEnvironment } from './dynamic-environment';

declare var window: any;

export const environment = {
    production: false,

    get api_host() {
        return window.config.api_host;
    }
}
