import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomizationService } from './customization.service';

declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class DynamicConfigService {

  client: string;
  private staticFilesVersion:string = '2021.12.21.01';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private customizationService: CustomizationService
  ) {}

  init(config: boolean = true, styles: boolean = true) : Promise<any> {
    if(styles) {
      this.loadTheme(this.customizationService.getTheme());
      this.loadStyle(this.customizationService.getStyle());
    }

    if(!config) return;

    return from( fetch(this.customizationService.getConfig() + '?v=' + this.staticFilesVersion).then( res => { return res.json() } ) )
      .pipe(
        map(config => {
          window.config = config;
          return;
        })
      ).toPromise();
  }

  reinit(config: boolean = true, styles: boolean = true) : Promise<any> {
    return this.init(config, styles);
  }

  private loadTheme(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];
  
    let themeLink = this.document.getElementById(
      'client-theme'
    ) as HTMLLinkElement;

    if (themeLink) {
      themeLink.href = styleName + '?v=' + this.staticFilesVersion;
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = styleName + '?v=' + this.staticFilesVersion;

      head.appendChild(style);
    }
  }

  public loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];
  
    let styleLink = this.document.getElementById(
      'client-style'
    ) as HTMLLinkElement;

    if (styleLink) {
      styleLink.href = styleName + '?v=' + this.staticFilesVersion;
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-style';
      style.rel = 'stylesheet';
      style.href = styleName + '?v=' + this.staticFilesVersion;

      head.appendChild(style);
    }
  }
}
