import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageChangerService } from 'src/app/services/language-changer.service';
import { LoginService } from 'src/app/services/login.service';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-navbar-items',
	templateUrl: './navbar-items.component.html',
	styleUrls: ['./navbar-items.component.scss']
})
export class NavbarItemsComponent implements OnInit {
	
	languages = [ { code: 'en', name: 'English' }, { code: 'it', name: 'Italiano' } ];
	preSelectedLang: undefined;

	location: Location;

	userData: any;

	constructor(
		private loginApi: LoginService,
		private router: Router,
		private langChanger: LanguageChangerService,
		private userService: UserService
	) {
		this.preSelectedLang = this.langChanger.getpreSelectedLang();
	}

	ngOnInit() { }

	refresh() {
		window.location.reload()
	}

	changeLang(langCode) {
		this.langChanger.addLanguageInStorage(langCode)
		this.refresh()
	}

	getIsAdmin() {
		this.userData = this.userService.getUserData();
		return this.userData.isAdmin;
	}

	logout() {
		setTimeout(() => {
			this.router.navigate(['/login']);
		}, 200)
	}
}
