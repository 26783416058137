import { Component, OnInit } from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
	user: any;
	public listTitles: any[];
	public location: Location;

	constructor(
		location: Location,
		public loginApi: LoginService,
		public route: ActivatedRoute,
		private userService: UserService
	) {
		this.location = location;
		this.user = this.userService.getUserData();
	}

	ngOnInit() {
		this.listTitles = ROUTES.filter(listTitle => listTitle);
	}

	getTitle() {
		var title = this.location.prepareExternalUrl(this.location.path());
		if (title.charAt(0) === '#') {
			title = title.slice(1);
		}
		for (var item = 0; item < this.listTitles.length; item++) {
			let re = new RegExp("^" + this.listTitles[item].path + "$");
			if (re.test(title)) {
				return this.listTitles[item].title;
			}
		}
		return '';
	}
}
