import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timeDiff'
})
export class TimeDiffPipe implements PipeTransform {

  transform(date1: any, date2?: any, xReturnNumber?: boolean): any {
   const diff = Math.abs(moment(date1).diff(moment(date2), "seconds"));
    var hours = Math.trunc(diff / 60 / 60);
    var minutes = Math.trunc((diff - hours*60*60) / 60);
    var seconds = diff - (minutes*60) - (hours*60*60);
    if(xReturnNumber === true) {
      return diff;
    } else {
      return `${hours.toString().padStart(2,"0")}:${minutes.toString().padStart(2,"0")}:${seconds.toString().padStart(2,"0")}`;
    }
  }

}
