import { AfterViewInit, Injectable } from '@angular/core';
import { CustomizationService } from './customization.service';

@Injectable({
	providedIn: 'root'
})
export class MediaBreakpointService {

	private width: number;
	private height: number;

	constructor(private customizationService:CustomizationService) {
		this.width = window.innerWidth;
		this.height = window.innerHeight;
	}
}
